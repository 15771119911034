<template>
  <div class="h-100">
    <!-- Navigation -->
    <header-vue></header-vue>

    <div class="chat-wrapper">

      <div class="chat-sidebar p-4 ">

        <div class="chat-sidebar-header border-0 mb-4" >
          <a href="#" data-toggle="dropdown" class="dropdown-link">
            <div class="d-flex align-items-center">
              <div class="avatar avatar-sm dm_avatar">
                <img v-if="user" :src="API_URL+user.name" class="rounded-circle" alt="">
                <img v-else src="https://via.placeholder.com/150" class="rounded-circle" alt="">
              </div>
              <h3 class="tx-color-01 tx-semibold mb-0 pl-3">{{ user.first_name }} {{ user.last_name }}</h3>
            </div>
          </a>
        </div><!-- chat-sidebar-header -->

        <!-- start sidebar body -->
        <div class="chat-sidebar-body">
          <!-- Search Widget -->
          <div class="input-group mb-3 dm_chat_search dm_card_shadow">
            <div class="input-group-prepend">
              <span class="input-group-text border-0 bg-white pl-4 py-3" id="basic-addon1"><i class="fa fa-search"></i></span>
            </div>
            <input type="text" v-model="search_text" class="form-control border-0 py-3" placeholder="Search users" aria-label="Username" aria-describedby="basic-addon1">
          </div>

          <div class="flex-fill pd-y-20 pd-x-10 overflow-auto">
            <div id="chatDirectMsg" class="chat-msg-list">

              <a class="media chat-users align-items-center" v-for="(user, index) in filterBy" v-bind:key="user.id"
                @click="getChannelMessages(user.id,user,index)" :class="'channel_list'+user.id">

                <div class="avatar avatar-sm dm_chat_avatar " :class="user.userList[0].is_online ? 'avatar-online' : 'avatar-offline'">
                  <img v-if="user.userList[0].photo" :src="API_URL+user.userList[0].photo.name" class="rounded-circle"
                    alt="">
                    <img v-else src="https://via.placeholder.com/150" class="rounded-circle" alt="">
                </div>
                <div class="media-body pl-3">
                  <h6 class="mg-b-0">{{ user.userList[0].first_name }} {{ user.userList[0].last_name }}</h6>
                  <small class="d-block tx-color-04">{{ user.userList[0].dog_name }}</small>
                </div><!-- media-body -->
                <span class="badge badge-danger" v-if="user.unread_count > 0">{{ user.unread_count }}</span>
              </a><!-- media -->

              <a class="media chat-users align-items-center" v-if="filterBy.length == 0">
                <div class="media-body mg-l-10">
                  <h6 class="mg-b-0">No Friends Found</h6>
                </div><!-- media-body -->
              </a><!-- media -->

            </div><!-- media-list -->
          </div>
        </div><!-- chat-sidebar-body -->

        <!-- <div class="chat-sidebar-footer" v-if="selected_user">
          <div class="d-flex align-items-center" v-if="selected_user.userList">
            <div class="avatar avatar-sm mg-r-8">
              <img v-if="selected_user.userList[0].photo" :src="API_URL+selected_user.userList[0].photo.name"
                class="rounded-circle" alt="">
            </div>
            <h6 class="mg-b-0">{{ selected_user.userList[0].first_name }}
              {{ selected_user.userList[0].last_name }}</h6>
          </div>
          <div class="d-flex align-items-center">
            <a href=""><i data-feather="mic"></i></a>
            <a href=""><i data-feather="settings"></i></a>
          </div>
        </div> -->
        <!-- chat-sidebar-footer -->

      </div><!-- chat-sidebar -->

      <div class="chat-content chat_content_mobile" v-if="selected_user.userList" >
        <div class="chat-content-header">
          <i class="fas fa-chevron-left d-lg-none" @click="getChatList()"></i>
          <span v-if="selected_user.userList" class="media chat-users align-items-center">
            <div class="avatar avatar-sm dm_chat_avatar"
              :class="selected_user.userList[0].is_online ? 'avatar-online' : 'avatar-offline'">
              <img v-if="selected_user.userList[0].photo" :src="API_URL+selected_user.userList[0].photo.name" class="rounded-circle" alt="">
              <img v-else src="https://via.placeholder.com/150" class="rounded-circle" alt="">
            </div>
            <div class="media-body pl-3">
              <h6 class="mg-b-0">{{ selected_user.userList[0].first_name }}
                {{ selected_user.userList[0].last_name }}
              </h6>
              <small v-if="!selected_user.userList[0].is_online" class="d-block tx-color-04">{{ lastSeenDateFormatTime(selected_user.userList[0].last_seen) }}</small>
            </div>
          </span>
        </div><!-- chat-content-header -->

        <div class="chat-content-body" style="overflow: auto;" v-if="selected_user">
        <div class="chat-group" v-if="selected_user.userList" :class="{'justify-content-center': selected_user.status == 'pending' && channel_messages[1] && selected_user.userList[0].id == channel_messages[1].user_id}">

            <div v-if="selected_user.status == 'pending' && channel_messages[1] && selected_user.userList[0].id == selected_user.created_by">
              <div class="dm_accept_avatar_wrapper d-flex align-items-center justify-content-center p-3">
                <div class="avatar avatar-md">
                  <img :src="API_URL+selected_user.userList[0].photo.name" class="rounded-circle" alt="">
                </div>
                <h3 class="mg-b-0 pl-3">{{ selected_user.userList[0].first_name }}
                  {{ selected_user.userList[0].last_name }}
                </h3>
              </div>
              <div class="dm_accept_buttons_wrapper" style="text-align: center;">
                <button @click="updateChannelStatus('accept')" class="dm_btn_dark_solid py-2 px-md-5 px-3 m-3">Accept</button>
                <button @click="updateChannelStatus('reject')" class="dm_btn_dark_solid py-2 px-md-5 px-3 m-3">Reject</button>
              </div>
            </div>

            <div v-else-if="selected_user.status == 'pending'">
              <div class="dm_accept_avatar_wrapper d-flex align-items-center justify-content-center p-3">
                <div class="avatar avatar-md">
                  <img :src="API_URL+selected_user.userList[0].photo.name" class="rounded-circle" alt="">
                </div>
                <h3 class="mg-b-0 pl-3">{{ selected_user.userList[0].first_name }}
                  {{ selected_user.userList[0].last_name }}
                </h3>
              </div>
              <div class="dm_accept_buttons_wrapper" style="text-align: center;">
                <button @click="updateChannelStatus('reject')" class="dm_btn_dark_solid py-2 px-md-5 px-3 m-3">Reject</button>
              </div>
            </div>

            <span v-if="channel_messages.length == 0">
              No Conversion Found
            </span>

            <div class="dm_chat_messages" v-for="messages in channel_messages" v-bind:key="messages.id">

              <div v-if="!messages.id" class="chat-group-divider">{{ messages }}</div>

              <span class="dm_message_in dm_messages d-flex position-relative mb-3 align-items-start"
                v-else-if="messages && selected_user.userList[0].id == messages.user_id">
                <div class="media">
                  <!-- <div class="avatar avatar-sm">
                      <img :src="API_URL+selected_user.userList[0].photo.name" class="rounded-circle" alt="">
                    </div> -->
                  <div class="media-body">
                    <div class="copyable-text">
                      <div v-if="messages.message_type == 'text'">
                        <span v-html="messages.message"></span>
                      </div>
                      <div class="dm_sent_image" v-if="messages.attachment_type == 'image'">
                        <img class="w-100" style="width: 20%" :src="API_CHAT_URL+messages.attachment">
                      </div>
                      <div v-if="messages.attachment_type == 'video'">
                        <video width="200" controls>
                          <source :src="API_CHAT_URL+messages.attachment" type="video/mp4">
                        </video>
                      </div>
                    </div>
                    <div class="time">
                      <span>{{ frontEndDateChatTimeFormat(messages.created_at) }}</span>
                    </div>
                    <!-- <h6>{{ selected_user.userList[0].first_name }} {{ selected_user.userList[0].last_name }}
                        <small>{{ frontEndDateTimeFormat(messages.created_at) }}</small></h6> -->
                    <!-- <span v-if="messages.message_type == 'text'">
                        <pre v-html="messages.message"></pre>
                        <small>{{ frontEndDateTimeFormat(messages.created_at) }}</small>
                      </span>
                      <span v-if="messages.attachment_type == 'image'">
                        <img style="width: 20%" :src="API_CHAT_URL+messages.attachment">
                      </span>
                      <span v-if="messages.attachment_type == 'video'">
                        <video width="200" controls>
                          <source :src="API_CHAT_URL+messages.attachment" type="video/mp4">
                        </video>
                      </span> -->

                  </div><!-- media-body -->
                </div><!-- media -->
              </span>

              <span class="dm_message_out dm_messages d-flex position-relative mb-3 align-items-end"
                v-else-if="user && messages && user.id == messages.user_id">
                <div class="media">
                  <!-- <div class="avatar avatar-sm">
                      <img :src="API_URL+user.name" class="rounded-circle" alt="">
                    </div> -->
                  <div class="media-body bg-gray-300">
                    <div class="copyable-text">
                      <div v-if="messages.message_type == 'text'">
                        <span v-html="messages.message"></span>
                      </div>
                      <div class="dm_sent_image" v-if="messages.attachment_type == 'image'">
                        <img class="w-100" :src="API_CHAT_URL+messages.attachment">
                      </div>
                      <div v-if="messages.attachment_type == 'video'">
                        <video width="200" controls>
                          <source :src="API_CHAT_URL+messages.attachment" type="video/mp4">
                        </video>
                      </div>
                    </div>
                    <div class="time">
                      <span class="mr-2">{{ frontEndDateChatTimeFormat(messages.created_at) }}</span>
                      <i v-if="messages.is_seen == 1" style="color: #add8e6" class="fas fa-check-double"></i>
                      <i v-else-if="messages.is_delivered == 1" class="fas fa-check-double"></i>
                      <i v-else-if="messages.is_sent == 1" class="fas fa-check"></i>
                    </div>

                    <!-- <h6>{{ user.first_name }} {{ user.last_name }}
                        <small>{{ frontEndDateTimeFormat(messages.created_at) }}</small></h6> -->
                    <!-- <span v-if="messages.message_type == 'text'">
                        <pre v-html="messages.message"></pre>
                        <small>{{ frontEndDateTimeFormat(messages.created_at) }}</small>
                      </span>
                      <span v-if="messages.attachment_type == 'image'">
                        <img style="width: 20%" :src="API_CHAT_URL+messages.attachment">
                      </span>
                      <span v-if="messages.attachment_type == 'video'">
                        <video width="300" controls>
                          <source :src="API_CHAT_URL+messages.attachment" type="video/mp4">
                        </video>
                      </span> -->
                    <!-- <i v-if="messages.is_seen == 1" style="color: #add8e6" class="fas fa-check-double"></i>
                      <i v-else-if="messages.is_delivered == 1" class="fas fa-check-double"></i>
                      <i v-else-if="messages.is_sent == 1" class="fas fa-check"></i> -->

                  </div><!-- media-body -->
                </div><!-- media -->
              </span>
            </div>

          </div>
          <div class="chat-group" v-else>
            <span> Please select the user </span>
          </div>
        </div><!-- chat-content-body -->

        <div class="chat-content-footer" v-if="selected_user.userList && selected_user.blocked_by == null">
          <input type="file" style="display: none;" name="" class="chat_attachment_file" v-on:change="handleFiles" accept="image/*">
          <input type="file" style="display: none;" name="" class="chat_attachment_video_file"
            v-on:change="handleVideoFiles" accept="video/*">
          <a data-toggle="tooltip" title="Add File" class="chat-plus" @click="addVideoFile">
            <i class="fas fa-video"></i>
          </a>
          <a data-toggle="tooltip" title="Add File" class="chat-plus" @click="addFile">
            <i class="fas fa-image"></i>
          </a>
          <textarea class="form-control align-self-center bd-0 input-description" rows="5" placeholder="Type a message..."
            style="height: 100%;">
          </textarea>
          <!-- <nav > -->
            <a data-toggle="tooltip" class="dm_send_message d-flex align-items-center p-3 border-left" @click="sendMessage()"><i style="font-size: 20px;" class="fas fa-paper-plane"></i></a>
          <!-- </nav> -->
        </div><!-- chat-content-footer -->
      </div><!-- chat-content -->

    </div><!-- chat-wrapper -->

    <div class="modal fade effect-scale" id="modalCreateChannel" tabindex="-1" role="dialog" aria-hidden="true">
      <div class="modal-dialog modal-dialog-centered modal-sm" role="document">
        <div class="modal-content">
          <div class="modal-body pd-20">
            <button type="button" class="close pos-absolute t-15 r-15" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true"><i data-feather="x" class="wd-20"></i></span>
            </button>

            <h6 class="tx-uppercase tx-spacing-1 tx-semibold mg-b-20">Create Channel</h6>
            <input type="text" class="form-control" placeholder="Channel name" value="">
          </div>
          <div class="modal-footer pd-x-20 pd-b-20 pd-t-0 bd-t-0">
            <button type="button" class="btn btn-secondary tx-13" data-dismiss="modal">Cancel</button>
            <button type="button" class="btn btn-primary tx-13">Create</button>
          </div>
        </div>
      </div>
    </div>

    <div class="modal fade effect-scale" id="modalInvitePeople" tabindex="-1" role="dialog" aria-hidden="true">
      <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
          <div class="modal-body pd-20 pd-sm-30">
            <button type="button" class="close pos-absolute t-20 r-20" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true"><i data-feather="x" class="wd-20"></i></span>
            </button>

            <h6 class="tx-18 tx-sm-20 mg-b-5">Invite People</h6>
            <p class="tx-color-03 mg-b-20">Share this link to your friend to grant access and join to this channel</p>
            <div class="input-group mg-b-5">
              <input type="text" class="form-control" value="http://themepixels.me/dashforge" readonly>
              <div class="input-group-append">
                <button class="btn btn-outline-light" type="button" id="button-addon2">Copy</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <footer-vue></footer-vue>
  </div>

</template>
<script>
import Headervue from '../partials/header-vue'
import Footervue from '../partials/footer-vue'
import {API_PROFILE_IMAGE_URL,API_CHAT_IMAGE_URL} from '../../constants'

export default {
    name: "chat",
    data: () => ({
      user_list:[],
      user:[],
      chat_channel_id:'',
      message:'',
      message_type:'text',
      attachment:null,
      attachment_type:null,
      selected_user:[],
      channel_messages:[],
      API_URL:API_PROFILE_IMAGE_URL,
      API_CHAT_URL:API_CHAT_IMAGE_URL,
      message_date:'',
      search_text:'',
      files:[],
      count:1,
      selected_index:0,
      // selected_chat_id: typeof this.$route.params.id != 'undefined' ? this.$route.params.id : '',
      // selected_chat_id: typeof this.$route.params.id != 'undefined' ? this.$route.params.id : '',
    }),
    components: {
      'header-vue': Headervue,
      'footer-vue': Footervue
    },
    computed: {
      filterBy () {
        window.$('.channel_list'+this.chat_channel_id).addClass('active');
        const search = this.search_text.toLowerCase().trim();
          // if(search == '' && typeof this.$route.params.id != 'undefined'){
          //   var user = this.user_list.filter(c => c.id == this.$route.params.id);
          //   this.getChannelMessages(user[0].id, user[0], 0);
          //   this.$forceUpdate();
          // }

          if (!search){
            return this.user_list;
          }

          return this.user_list.filter(c => c.userList[0].first_name.toLowerCase().indexOf(search) > -1 || c.userList[0].last_name.toLowerCase().indexOf(search) > -1);
      }
    },
    sockets: {
      'chat-message': function (data) {
        var _this = this;
        if(_this.selected_user.status == 'pending'){
          window.notify(false, 'The request is still in pending state');
          return false;
        }
        if(data.chat_channel_id == this.chat_channel_id){

          if (data && _this.selected_user.userList[0].id == data.user_id){
            let msgForm = {
              chat_channel_id: data.chat_channel_id,
              message_id: data.message_id
            }
            _this.$socket.emit('user:chat-message-seen',msgForm, function (data) {
                console.log(data);
            });
            data.is_seen = 1;

          }else if (_this.selected_user.userList[0].is_online){
            let msgForm = {
              chat_channel_id: data.chat_channel_id,
              message_id: data.message_id
            }
            _this.$socket.emit('user:chat-message-delivered',msgForm, function (data) {
                console.log(data);
            });
            data.is_delivered = 1;
          }

          this.channel_messages.push(data);

        }else{
          for (var i = 0; i < _this.user_list.length; i++) {
            if(data.chat_channel_id == _this.user_list[i].id){
              _this.user_list[i].unread_count = _this.user_list[i].unread_count + 1;

            }
          }
          _this.$forceUpdate();
        }

          window.$(".chat_attachment_file").val('');
          window.$(".chat_attachment_video_file").val('');
          window.$('.chat-content-body').stop().animate({
            scrollTop: window.$('.chat-content-body')[0].scrollHeight + 1500
          }, 800);
          window.$(".emojionearea-editor").data("emojioneArea").setText('');
          _this.attachment = null;
          _this.attachment_type = null;
          _this.message_type = 'text';
      },
      'chat-message-seen': function(data) {
        var _this = this;
        window.setTimeout(function(){
          for (var i = 0; i < _this.channel_messages.length; i++) {
            if(typeof _this.channel_messages[i].id != 'undefined' && _this.channel_messages[i].message_id == data.message_id){
              console.log(_this.channel_messages[i]);
              _this.channel_messages[i].is_seen = 1;
            }
          }
          _this.$forceUpdate();
        }, 500);
      }
    },

    methods: {
      getChatList: function () {
      window.$('.chat-content').removeClass('chat_content_mobile');
      window.$('.chat-sidebar').removeClass('chat_sidebar_mobile');
    },
      sendMessage: function () {
        if (this.$parent.checkSubcription()) {
          if(this.selected_user.status == 'pending'){
            window.notify(false, 'The request is still in pending state');
            return false;
          }
          if((typeof window.$(".emojionearea-editor").data("emojioneArea") != 'undefined' && window.$(".emojionearea-editor").data("emojioneArea").getText() != '') || window.$(".chat_attachment_file")[0].files.length > 0 || window.$(".chat_attachment_video_file")[0].files.length > 0){
            let time_stamp = new Date().getTime();
            let form = {
                chat_channel_id: this.chat_channel_id,
                message: typeof window.$(".emojionearea-editor").data("emojioneArea") != 'undefined' ? window.$(".emojionearea-editor").data("emojioneArea").getText() : '',
                message_type: this.message_type,
                attachment: this.attachment,
                attachment_type: this.attachment_type,
                message_id: this.chat_channel_id+"-"+time_stamp+"-"+this.user.id,
            };
            this.$socket.emit('user:chat-message', form, function (data) {
                if (data) {
                  if(typeof window.$('.chat-content-body')[0].scrollHeight != 'undefined'){
                    window.$('.chat-content-body').stop().animate({
                      scrollTop: window.$('.chat-content-body')[0].scrollHeight + 1500
                    }, 800);
                  }
                }
            });
          }
        }
      },
      addFile: function () {
        window.$('.chat_attachment_file').trigger('click');
      },
      addVideoFile: function () {
        window.$('.chat_attachment_video_file').trigger('click');
      },
      handleFiles: function () {
        this.attachment_type = 'image';
        this.files = window.$(".chat_attachment_file")[0].files;
        this.uploadChatFiles();
      },
      handleVideoFiles: function () {
        this.attachment_type = 'video';
        this.files = window.$(".chat_attachment_video_file")[0].files;
        this.uploadChatFiles();
      },
      frontEndDateFormat: function (dates) {
        let date = window.moment(dates);
        return window.moment(date, 'YYYY-MM-DD').format('MMMM DD, YYYY');
      },
      formatTimeStamp: function (dates) {
        let date = window.moment(dates);
        return window.moment(date, 'YYYY-MM-DD').format('YYYY-MM-DD HH:mm:ss');
      },
      formatTimeStampUtc: function (dates) {
        let date = window.moment(dates);
        return window.moment.utc(date).format('YYYY-MM-DD HH:mm:ss');
      },
      frontEndDateChatTimeFormat: function (dates) {
        let date = window.moment(dates);
        return window.moment(date).format('hh:mm A');
      },
      frontEndDateTimeFormat: function (dates) {
        let date = window.moment(dates);
        if (window.moment().diff(date, 'days') >= 1) {
            return date.fromNow()+' '+window.moment(date, 'YYYY-MM-DD').format('hh:mm A'); // '2 days ago' etc.
        }
        return date.calendar().split(' ')[0]+' '+window.moment(date, 'YYYY-MM-DD').format('hh:mm A');
      },
      lastSeenDateFormatTime: function(dates) {
          var exp = window.moment();
          var now = window.moment(dates);

          let days = exp.diff(now, 'days');
          let hours = exp.diff(now, 'hours') - (days * 24);
          let minutes = exp.diff(now, 'minutes') - ((days * 1440) + (hours * 24) * 60);

          if(dates != null){
              if(days > 0){
                  return days+' Days ago'
              }else if(hours > 0){
                  return hours+' Hours ago';
              }else{
                  return minutes+' Minutes ago';
              }
          }else{
              return '';
          }
      },
      async uploadChatFiles() {
        var _this = this;
        let form = {
            files: this.files,
        };

        let responce = await this.$store.dispatch("uploadChatFiles",form);
        if (responce.status) {
          console.log(responce.data);
          _this.message_type = 'image'
          _this.attachment = responce.data.data[0];
          _this.sendMessage();
          window.notify(true, 'File uploaded successfully');
        }
      },
      async getAuthUser() {
          let responce = await this.$store.dispatch("getAuthUser");
          if (responce.status) {
              this.user = responce.data.data;
          }
      },
      async getfirendsList() {
          //await this.$store.dispatch("updateRequest");
          let responce = await this.$store.dispatch("chatRecents");
          if (responce.status) {
              this.user_list = responce.data.data;
          }
      },
      async getPreviousChannelMessages() {
        if(this.channel_messages[1] && typeof this.channel_messages[1].created_at != 'undefined'){
          var _this = this;
          let time = _this.formatTimeStampUtc(_this.channel_messages[1].created_at);

            let form = {
                chat_channel_id: _this.chat_channel_id,
                timestamp: time
            };

            let responce = await _this.$store.dispatch("ChannelMessages",form);
            if (responce.status) {
              let responceData = responce.data.data;
                if(responceData.length > 0){
                  if(typeof _this.channel_messages[0].created_at == 'undefined'){
                    _this.message_date = _this.channel_messages[0];
                    _this.channel_messages.shift();
                  }

                  for (var i = 0; i < responceData.length; i++) {
                    if(_this.message_date != _this.frontEndDateFormat(responceData[i].created_at)){
                      _this.channel_messages.unshift(_this.message_date);
                      _this.message_date = _this.frontEndDateFormat(responceData[i].created_at);
                    }
                    _this.channel_messages.unshift(responceData[i]);

                    if(responceData[i].is_seen == 0){
                      let msgForm = {
                        chat_channel_id: responceData[i].chat_channel_id,
                        message_id: responceData[i].message_id
                      }
                      _this.$socket.emit('user:chat-message-seen',msgForm, function (data) {
                          console.log(data);
                      });
                    }
                  }
                  _this.channel_messages.unshift(_this.message_date);
              }
            }
          }
      },
      async updateChannelStatus(status) {
        var _this = this;
        let msgForm = {
          chat_channel_id: this.chat_channel_id,
          status: status
        }
        let responce = await this.$store.dispatch("updateChannelStatus", msgForm);
        if(responce.data.status){
          if(status == 'reject'){
            _this.selected_user = [];
            _this.selected_index = 0;
            _this.user_list.splice(_this.selected_index,1);
            window.notify(responce.data.status, 'User rejected successfully');
          }else{
            _this.selected_user.status = 'active';
            _this.$forceUpdate();
          }
        }
      },
      async getChannelMessages(id, user, index) {
          var _this = this;
          this.channel_messages = [];
          this.message_date = '';
          this.selected_index = index;
          window.$('.chat-users').removeClass('active');
          window.$('.channel_list'+id).addClass('active');
          window.$("div.emojionearea-editor").text('');
          window.$('.chat-content').addClass('chat_content_mobile');
          window.$('.chat-sidebar').addClass('chat_sidebar_mobile');
          this.chat_channel_id = id;
          this.selected_user = user;
          this.selected_user.unread_count = 0;
          let form = {
              chat_channel_id: this.chat_channel_id,
              timestamp: ''
          };
          let responce = await this.$store.dispatch("ChannelMessages",form);
          if (responce.status) {
            let responceData = responce.data.data.reverse();
              for (var i = 0; i < responceData.length; i++) {

                if(responceData[i].is_seen == 0 && _this.user.id != responceData[i].user_id){
                  let msgForm = {
                    chat_channel_id: responceData[i].chat_channel_id,
                    message_id: responceData[i].message_id
                  }
                  _this.$socket.emit('user:chat-message-seen',msgForm, function (data) {
                      console.log(data);
                  });
                  responceData[i].is_seen = 1;
                }

                if(_this.message_date != _this.frontEndDateFormat(responceData[i].created_at)){
                  _this.channel_messages.push(_this.frontEndDateFormat(responceData[i].created_at));
                  _this.message_date = _this.frontEndDateFormat(responceData[i].created_at);
                }
                _this.channel_messages.push(responceData[i]);

              }
              window.$('.chat-content-body').stop().animate({
                scrollTop: window.$('.chat-content-body')[0].scrollHeight + 1500
              }, 800);
          }
        if(_this.count == 1 && _this.selected_user.blocked_by == null){
          window.$(".input-description").emojioneArea({
              inline: true,
              hideSource: true,
              pickerPosition: "top",
              tonesStyle: "bullet",
              events: {
                keyup: function(editor, evt) {
                  if (evt.which === 13) {
                    console.log('Enter');
                    _this.sendMessage();
                    evt.preventDefault();
                  }
                }
              }
          });
          _this.count = 0;

          window.$('.chat-content-body').on('scroll', function() {
              if(window.$('.chat-content-body').scrollTop() == 0) {
                _this.getPreviousChannelMessages();
              }
          })
        }
        window.setTimeout(function(){ 
          var emoji = document.getElementsByClassName('emojionearea-editor');
          var textarea = document.getElementsByClassName('input-description');
          if (emoji.length == 0 && textarea.length > 0) {
               window.$(".input-description").emojioneArea({
                  inline: true,
                  hideSource: true,
                  pickerPosition: "top",
                  tonesStyle: "bullet",
                  events: {
                    keyup: function(editor, evt) {
                      if (evt.which === 13) {
                        console.log('Enter');
                        _this.sendMessage();
                        evt.preventDefault();
                      }
                    }
                  }
              });
          }
        }, 800);

        window.$(".emojionearea-editor").text('');
        window.$(".chat_attachment_file").val('');
        window.$(".chat_attachment_video_file").val('');

      },
    },
    created() {
      this.getAuthUser();
      this.getfirendsList();
    }
};
</script>